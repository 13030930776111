import {useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, NavLink
} from "react-router-dom";
import headerImage from "./assets/testHeader.jpg";
import flowers from "./assets/login-top.png";
import {accomodationContent, foodAndDrinkContent, ourWeddingContent, importantInformationContent} from "./content";
import RSVPForm from "./components/RSVPForm/rsvpForm";
import Dashboard from "./components/Dashboard/dashboard";
import LoginForm from "./components/LoginForm/loginForm";


const App = () => {
    const [navActive, setNavActive] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem("loggedIn"));

    const toggleNavOpen = () => setNavActive(prev => !prev);
    const closeNav = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setNavActive(false);
    }

    if (!isLoggedIn) {
        return <LoginForm setIsLoggedIn={setIsLoggedIn}/>
    }

    if (isLoggedIn === "ADMIN") {
        return <Dashboard/>
    }


    return (
        <Router>
            <div className={"container"}>
                <header>
                    <div className="siteHeading">
                        <img src={flowers} alt="" className="flowers"/>
                        <h1>The Invitation</h1>
                        <h2>Amy & Will - 24th June 2023</h2>
                    </div>
                    <nav>
                        <button onClick={toggleNavOpen}
                                className={navActive ? "navTrigger navigationActive" : "navTrigger"}>
                            <span className="textHidden">{navActive ? "Close" : "Menu"}</span>
                            <div className="hamburgerContainer">
                                <div className={navActive ? "hamburger is-active" : "hamburger"} id="hamburger-5">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </div>
                            </div>
                        </button>
                        <ul className={navActive ? "navigation navigationActive" : "navigation"}>
                            <li><NavLink exact to="/" onClick={closeNav}>Our Wedding</NavLink></li>
                            <li><NavLink to="accomodation" onClick={closeNav}>Accommodation</NavLink></li>
                            <li><NavLink to="food-and-drink" onClick={closeNav}>Menu</NavLink></li>
                            <li><NavLink to="important-information" onClick={closeNav}>Important Information</NavLink>
                            </li>
                            <li><NavLink to="rsvp" onClick={closeNav}>RSVP</NavLink></li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <img src={headerImage} alt="" className="banner" width={2046} height={850}/>

                    <div className="mainTextContent">
                        <Switch>
                            <Route path="/accomodation">
                                {accomodationContent}
                            </Route>

                            <Route path="/food-and-drink">
                                {foodAndDrinkContent}
                            </Route>

                            <Route path="/important-information">
                                {importantInformationContent}
                            </Route>

                            <Route path="/rsvp">
                                <RSVPForm/>
                            </Route>

                            <Route path="/dashboard">
                                <Dashboard/>
                            </Route>


                            <Route path="/">
                                {ourWeddingContent}
                            </Route>

                        </Switch>
                    </div>
                </main>

                <img src={flowers} alt="" className="flowers flowersBottom"/>
            </div>

            <footer>
                <span>Amy & Will - 24th June 2023</span>
                <address>
                    <ul className="footerAddress">
                        <li>Camp Katur</li>
                        <li>The Camp Hill Estate</li>
                        <li>Kirklington</li>
                        <li>North Yorkshire</li>
                        <li>DL8 2LS</li>
                    </ul>
                </address>
            </footer>
            {navActive ? <div className="fullPageMask"></div> : null}
        </Router>
    )
}

export default App;
