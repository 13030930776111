import {useState} from "react";
import flowers from "../../assets/login-top.png";

const LoginForm = (props) => {
    const {setIsLoggedIn} = props;
    const [loginData, setLoginData] = useState({
        username: "",
        password: ""
    });
    const [validationFailed, setValidationFailed] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (loginData.username === "guest" && loginData.password === "leoandkoda") {
            setIsLoggedIn("GUEST");
            sessionStorage.setItem("loggedIn", "GUEST");
        } else if (loginData.username === "admin" && loginData.password === "mushroom") {
            setIsLoggedIn("ADMIN");
            sessionStorage.setItem("loggedIn", "ADMIN");
        } else {
            setValidationFailed(true);
        }
    }

    const handleDataChange = (field, value) => {
        setValidationFailed(false);
        setLoginData(prev => ({...prev, [field]: value}))
    }

    const {username, password} = loginData;

    return (
        <div className="container containerSmall">
            <div className="siteHeading">
                <img src={flowers} alt="" className="flowers"/>
                <h1>The Invitation</h1>
                <h2>Amy & Will - 24th June 2023</h2>
            </div>

            <form onSubmit={e => handleSubmit(e)} className={validationFailed ? "formWithError" : null}>
                <div className="formField">
                    <label htmlFor="username">Username:</label>
                    <input type="text" id="username" name="username" required={true} value={username}
                           onChange={e => handleDataChange("username", e.target.value)}/>
                </div>

                <div className="formField">
                    <label htmlFor="lastName">Password:</label>
                    <input type="password" id="password" name="password" required={true} value={password}
                           onChange={e => handleDataChange("password", e.target.value)}/>
                </div>

                {validationFailed ? <p className="errorMessage">Please check details and try again</p> : null}

                <button type="submit" className="submitButton">Submit</button>
            </form>
        </div>
    )
}


export default LoginForm;
