import {useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {createGuest} from "../../actions/guests";

const initState = {
    firstName: "",
    lastName: "",
    starter: "",
    main: "",
    dessert: "",
    dietaryRequirements: "",
    accommodation: "",
    accommodationRequirements: ""
}

const RSVPForm = () => {
    const [guestData, setGuestData] = useState(initState)
    const [sent, setSent] = useState(false);
    const accommodationSelection = guestData.accommodation;
    let accommodationMessage;
    if (accommodationSelection) {
        accommodationMessage =
            <p className="formMessage">{accommodationSelection === "meadow" ? "By selecting meadow you are aware that we will assign you accommodation on-site, this will be between £100-£140pp and we will require payment in full by 17/03/2023" : "By selecting offsite you are confirming that you do not need us to reserve you accommodation and you are fully responsible for sorting your own stay"}</p>
    }

    const dispatch = useDispatch();


    const handleDataChange = (field, value) => {
        setGuestData(prev => ({...prev, [field]: value}))
    }

    const handleSend = async () => {
        try {
            await axios.post("https://wedding-rsvp.herokuapp.com/send_mail", {guest: guestData})
        } catch (e) {
            console.error(e.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleSend().then(r => console.log(123,r));
        setSent(true);
        dispatch(createGuest(guestData));
        setGuestData(initState)

    }

    const completedContent = <div className="completed">
        <p>Thank you for your RSVP, we look forward to seeing you on the day!</p>

        <p>If you have more guests to RSVP for please click below</p>

        <button className="submitButton" onClick={() => setSent(false)}>Add another guest</button>
    </div>

    return (
        <>
            <h3>RSVP</h3>

            {sent ? completedContent :
                <form onSubmit={e => handleSubmit(e)}>
                    <p><strong>Please remember to complete this form for each guest in your party.</strong></p>
                    <h6>Your Details</h6>

                    <div className="formField">
                        <label htmlFor="firstName">First name:</label>
                        <input type="text" id="firstName" name="firstName" required={true} value={guestData.firstName}
                               onChange={e => handleDataChange("firstName", e.target.value)}/>
                    </div>

                    <div className="formField">
                        <label htmlFor="lastName">Last name:</label>
                        <input type="text" id="lastName" name="lastName" required={true} value={guestData.lastName}
                               onChange={e => handleDataChange("lastName", e.target.value)}/>
                    </div>

                    <hr/>
                    <h6>Menu</h6>

                    <div className="formField">
                        <label htmlFor="starter">Starter</label>
                        <select name="starter" id="starter" required={true} value={guestData.starter}
                                onChange={e => handleDataChange("starter", e.target.value)}>
                            <option value="" disabled={true}>Please select</option>
                            <option value="gnocchi">Gnocchi with Salsa Verde and Parmesan Shavings</option>
                            <option value="ravioli">Shallot and Pea Ravioli with asparagus, garden peas, broad beans and
                                a
                                Pernod and Shallot Sauce
                            </option>
                            <option value="ciabatta">Wild Garlic Mushroom on Toasted Ciabatta and Herb Oil</option>
                        </select>
                    </div>

                    <div className="formField">
                        <label htmlFor="main">Main</label>
                        <select name="main" id="main" required={true} value={guestData.main}
                                onChange={e => handleDataChange("main", e.target.value)}>
                            <option value="" disabled={true}>Please select</option>
                            <option value="curry">Thai Red Vegetable Curry, served with Fragrant Jasmine Rice and Prawn
                                Crackers
                            </option>
                            <option value="linguine">Cepe Linguine in a Truffle Cream with Fresh Popped Peas and Broad
                                Beans, Pea Puree and Truffle Oil
                            </option>
                            <option value="risotto">Butternut Squash Terrine with Pine Nut Risotto, Tenderstem Broccoli
                                and
                                Pickled Squash
                            </option>
                        </select>
                    </div>

                    <div className="formField">
                        <label htmlFor="dessert">Dessert</label>
                        <select name="dessert" id="dessert" required={true} value={guestData.dessert}
                                onChange={e => handleDataChange("dessert", e.target.value)}>
                            <option value="" disabled={true}>Please select</option>
                            <option value="panna">Coconut Panna Cotta with Mango Salsa</option>
                            <option value="stp">Sticky Toffee Pudding with Salted Caramel Ice Cream</option>
                        </select>
                    </div>

                    <div className="formField">
                        <label htmlFor="dietaryRequirements">Dietary Requirements</label>
                        <textarea id="dietaryRequirements" name="dietaryRequirements" placeholder="Any allergies etc.."
                                  value={guestData.dietaryRequirements}
                                  onChange={e => handleDataChange("dietaryRequirements", e.target.value)}/>
                    </div>

                    <hr/>

                    <h6>Where you'll stay</h6>

                    <div className="formField">
                        {accommodationMessage}
                        <label htmlFor="accommodation">Accommodation</label>
                        <select name="accommodation" id="accommodation" required={true} value={guestData.accommodation}
                                onChange={(e) => handleDataChange("accommodation", e.target.value)}>
                            <option value="" disabled={true}>Please select</option>
                            <option value="meadow">Please sort me accommodation onsite at The Meadow</option>
                            <option value="offsite">I will sort my own accommodation (Hill Farm Geodomes or off site)
                            </option>
                        </select>
                    </div>

                    <div className="formField">
                        <label htmlFor="accommodationRequirements">Accommodation Requirements</label>
                        <textarea id="accommodationRequirements" name="accommodationRequirements"
                                  value={guestData.accommodationRequirements}
                                  onChange={e => handleDataChange("accommodationRequirements", e.target.value)}/>
                    </div>


                    <button type="submit" className="submitButton">Submit</button>
                </form>
            }
        </>
    )
}

export default RSVPForm;
