export const ourWeddingContent = <>
    <p>Our wedding will take place on 24th June 2023 at Camp Katur on The Camp Hill Estate.</p>

    <p>Please be seated in the Woodland Chapel by 12:30 for the ceremony to begin at 13:00.</p>

    <p>Children are welcome to attend the weekend but we respectfully request that youngsters under 6 are not present
        at the ceremony as the groom is sensitive to crying and screaming. Thank you for your understanding. </p>

    <p><strong>Please RSVP by 02/06/23</strong></p>

    <p>We hope you can join us for the weekend as we have accommodation available from Friday afternoon to Sunday. More
        information is provided on the next page.</p>

    <h6>Full Venue Details:</h6>

    <p>
        Camp Katur<br/>
        The Camp Hill Estate<br/>
        Kirklington<br/>
        North Yorkshire<br/>
        DL8 2LS
    </p>

    <p>Venue website: <a href="https://campkatur.com" target="_blank" rel="nofollow noopener">campkatur.com</a></p>

    <hr/>

    <h6>Order of the Day</h6>

    <ol className="orderOfTheDay">
        <li><strong>12:30</strong> Please be seated in the Woodland Chapel</li>

        <li><strong>13:00</strong> Wedding Ceremony</li>

        <li><strong>13:30</strong> Woodland drinks reception and snacks</li>

        <li><strong>15:00</strong> Wedding Breakfast in The Tipi</li>

        <li><strong>From 18:00</strong> Céilí dance and DJ</li>

        <li><strong>20:00</strong> Evening Food</li>

        <li><strong>01:00</strong> Lights out</li>
    </ol>

</>

export const accomodationContent = <>
        <h3>Accommodation</h3>

        <h6>The Meadow</h6>

        <p>We hope most guests will stay on The Meadow where there is availability for roughly 80 guests. When you RSVP
            please confirm if you wish to stay on the Meadow and we will assign you accommodation where possible. If we are
            unable to find a place for you we will inform you as soon as possible and help you make other arrangements.</p>

        <p>Cost: £100 to £140 per person for the weekend depending on the accommodation you are assigned</p>

        <p>Things to note</p>

        <p>Most of the accommodation on The Meadow is off-grid which means <strong>no electricity!</strong></p>

        <p>Accommodation will be available from 3pm Friday to 11am Sunday but if you wish to arrive Saturday morning that’s
            not a problem</p>

        <p>Once we have assigned accommodation we will be in touch with the finer details about your stay</p>

        <p><strong>We will require payment in full by 17/03/23</strong></p>
        <hr/>

        <h6>Hill Farm</h6>
        <p>There are a very limited number of Hill Farm Geodomes available to book directly through the Camp Katur website
            or
            booking.com which each sleep up to two adults and two children. These are still within the venue grounds but are
            located on an adjacent field 5 minutes from the Meadow. If you wish to book one of these please do so
            straightaway
            and let us know when you RSVP. This is important, so we know not to assign you accommodation on The Meadow.</p>

        <h6>Things to note:</h6>

        <p>All the Hill Farm Geodomes have access to electricity</p>
        <p>They are more expensive with prices ranging from £417 up to £490 per geodome for the weekend</p>
        <p>Some of these geodomes have hot tubs and some don’t so check when booking</p>

        <hr/>
        <h6>Things to bring</h6>

        <p>Warm clothing for round the fire pit and footwear for roaming the forest and torches. Please also bring towels
            for
            your use and charcoal if you wish to use the BBQ outside for feasting the evening away.</p>
        <hr/>

        <h6>Off-site accommodation</h6>

        <p>You are also very welcome to stay off-site in a nearby bnb if the glamping vibe isn’t for you, but please let us
            know when you RSVP so we know not to assign you a place on The Meadow.</p>
    </>
;
export const foodAndDrinkContent = <>
    <h3>Menu</h3>

    <h6>Starters</h6>
    <ul>
        <li>Gnocchi with Salsa Verde and Parmesan Shavings</li>
        <li>Shallot and Pea Ravioli with asparagus, garden peas, broad beans and a Pernod and Shallot Sauce</li>
        <li>Wild Garlic Mushroom on Toasted Ciabatta and Herb Oil</li>
    </ul>

    <hr/>


    <h6>Mains</h6>
    <ul>
        <li>Thai Red Vegetable Curry, served with Fragrant Jasmine Rice and Prawn Crackers</li>
        <li>Cepe Linguine in a Truffle Cream with Fresh Popped Peas and Broad Beans, Pea Puree and Truffle Oil</li>
        <li>Butternut Squash Terrine with Pine Nut Risotto, Tenderstem Broccoli and Pickled Squash</li>
    </ul>

    <hr/>

    <h6>Dessert</h6>
    <ul>
        <li>Coconut Panna Cotta with Mango Salsa</li>
        <li>Sticky Toffee Pudding with Salted Caramel Ice Cream</li>
    </ul>

    <hr/>

    <h6>Evening food</h6>
    <ul>
        <li>Festival style- Hot Dogs and Crepes</li>
    </ul>
</>;


export const importantInformationContent = <>
    <h3>
        Important Information
    </h3>

    <ul>
        <li>Camp Katur is an outdoor rustic wedding venue so although it is a formal occasion please come prepared for
            anything that a British summer might throw at us… coats and wellies might need to be added to your outfit.
        </li>
        <li>Accommodation is available from 3pm on the Friday with checkout at 10am on the Sunday</li>
        <li>The Friday night will be a casual occasion and a chance to mingle. The Hebridean (onsite pub) will be
            serving food and drinks, or if you fancy getting into the camping spirit then you can fire up your BBQ or
            make a stonebaked pizza on The Meadow
        </li>
        <li> Please ensure you bring something for breakfast on the Saturday morning – there are fridges on site for
            milk etc.
        </li>
        <li>Feel free to bring your own alcohol to drink on the Friday night and Saturday morning but please be aware it
            cannot be consumed when the wedding bar is running
        </li>
        <li>There will be a Céilí dance to get the party started so bring your dancing shoes so you can join the bride
            as she swaps her glass slippers to cowboy boots
        </li>
        <li>You will need a torch</li>
        <li>Please RSVP by 02/06/23</li>
    </ul>

</>
