import * as api from "../api";

export const getGuests = () => async (dispatch) => {
    try {
        const {data} = await api.fetchGuests();

        dispatch({type: "FETCH_ALL", payload: data});
    } catch (e) {
        console.error(e.message);
    }
}


export const createGuest = (guest) => async (dispatch) => {
    try {
        const {data} = await api.createGuest(guest);

        dispatch({type: "CREATE", payload: data});
    } catch (e) {
        console.error(e.message);
    }
}
