import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getGuests} from "../../actions/guests";
import {useSelector} from "react-redux";

const Dashboard = () => {
    const dispatch = useDispatch();
    const guests = useSelector((state) => state.guests);

    useEffect(() => {
        dispatch(getGuests());
    }, [dispatch, getGuests]);


    return (
        <>
            <div className="total">Total responses = {guests.length}</div>
            <table className="guestTable">
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Starter</th>
                    <th>Main</th>
                    <th>Dessert</th>
                    <th className="large">Dietary Requirements</th>
                    <th>Accommodation</th>
                    <th className="large">Accommodation Requirements</th>
                </tr>
                {guests.map(guest => {
                    const {
                        firstName,
                        lastName,
                        starter,
                        main,
                        dessert,
                        dietaryRequirements,
                        accommodation,
                        accommodationRequirements
                    } = guest;
                    return <tr>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{starter}</td>
                        <td>{main}</td>
                        <td>{dessert}</td>
                        <td className="large">{dietaryRequirements ? dietaryRequirements : "-"}</td>
                        <td>{accommodation}</td>
                        <td className="large">{accommodationRequirements ? accommodationRequirements : "-"}</td>
                    </tr>
                })}
            </table>
        </>
    )
}

export default Dashboard;
