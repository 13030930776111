export default (guests = [], action) => {
    switch (action.type) {
        case "FETCH_ALL":
            return action.payload;
            break;
        case "CREATE":
            return [...guests, action.payload];
            break;
        default:
            return guests;
    }
}
